<template>
  <div class="widgetContainer widgetContainer--center widgetContainer--scrollable businessDetails">
    <div class="navigationHeader">
      <div class="navigationHeader__inner">
        <span
          v-if="$businessChecking === true && $personalChecking === true"
          class="icon-Arrow-big-left cancel"
          @click="back" />
        <span v-else>&nbsp;</span>
        <p class="title">
          {{ $t('kyb_NavTitle') }}
        </p>
      </div>
    </div>
    <div class="widgetContainer__body">
      <el-form
        @keyup.enter.native="submitForm()"
        :model="business"
        ref="businessForm"
        :rules="formRules"
        @submit.prevent.native
        class="wise-form">
        <el-form-item
          class="is-no-asterisk"
          prop="legalName"
          :label="$t('businessName')">
          <el-input v-model="business.legalName" />
        </el-form-item>

        <el-form-item class="is-no-asterisk" prop="dba" :label="$t('dbaName')">
          <el-input v-model="business.dba" type="dba" />
        </el-form-item>

        <el-form-item
          class="is-no-asterisk column-input"
          prop="entityType"
          :label="$t('entityType')">
          <el-select
            class="form-control1 role-select"
            required
            v-model="business.entityType">
            <el-option
              v-for="entityType in entityTypes"
              :key="entityType.key"
              :value="entityType.key"
              :label="entityType.label" />
          </el-select>
        </el-form-item>

        <el-form-item
          class="is-no-asterisk"
          required
          prop="idNumber"
          :label="$t('ein')">
          <EINInput v-model="business.idNumber" :autofocus="false" />
        </el-form-item>
        <p class="wise-form__info">
          {{ $t('ein_Message') }}
        </p>
      </el-form>
      <div class="widgetContainer__footer--fixed">
        <el-button type="primary" class="el-button__brand brand" @click="submitForm()" :disabled="isFormValid">
          {{ $t('kyb_NavRightButton_Title') }}
        </el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import EINInput from '@/components/EINInput';
import kyb from '../mixins/kyb';
export default {
  name: 'BusinessDetails',
  mixins: [kyb],
  components: {
    EINInput
  },
  created() {
    /** @type {import('@m/kyb/store/state').kybState['selectedBusinessType]}*/
    const selectedBusiness = this.getSelectedBusiness;
    if (selectedBusiness) {
      const { legalName, entityType, dba, idNumber, idType } = selectedBusiness;

      this.business = {
        legalName,
        entityType,
        dba,
        idNumber,
        idType
      };
    } else {
      const loader = this.showLoader();
      this.listAllBusiness()
        .then((r) => {
          if (r.total !== 0) {
            /** @type {import('@m/kyb/store/state').kybState['bussinessDetails']} */
            const businessList = r.data;
            this.setSelectedBusiness(businessList[0]);
          }
        })
        .catch((e) => {
          console.log(e);
          this.apiErrorMessage(e);
        })
        .finally(() => {
          loader.close();
        });
    }
  },
  computed: {
    ...mapGetters('kyb', ['getSelectedBusiness']),
    ...mapGetters('user', ['getPersonDetails']),
    ...mapGetters('account', ['getAllAccount']),
    isFormValid() {
      if (
        this.business.legalName &&
        this.business.entityType &&
        this.business.idNumber &&
        this.business.idNumber.length === 9
      ) {
        return false;
      } else {
        return true;
      }
    }
  },
  data() {
    return {
      /** @type {import('@m/kyb/store/state').kybState['selectedBusinessType]}*/
      business: {
        legalName: '',
        entityType: '',
        dba: '',
        idNumber: '',
        idType: 'ein'
      },
      formRules: {
        legalName: [
          {
            required: true,
            message: 'Legal Name is required'
          }
        ],
        entityType: [
          {
            required: true,
            message: 'Entity type is required'
          }
        ],
        idNumber: [
          {
            required: true,
            message: 'EIN is required'
          }
        ]
      },
      entityTypes: [
        { key: 'soleProprietor', label: 'Sole Proprietor' },
        { key: 'singleMemberLLC', label: 'Single Member LLC' },
        { key: 'limitedLiabilityCompany', label: 'Limited Liability Company' },
        { key: 'generalPartnership', label: 'General Partnership' },
        { key: 'unlistedCorporation', label: 'Corporation' },
        {
          key: 'publiclyTradedCorporation',
          label: 'Publicly Traded Corporation'
        },
        { key: 'association', label: 'Association' },
        { key: 'nonProfit', label: 'Non Profit' },
        { key: 'governmentOrganization', label: 'Government Organization' },
        { key: 'revocableTrust', label: 'Revocable Trust' },
        { key: 'irrevocableTrust', label: 'Irrevocable Trust' },
        { key: 'estate', label: 'estate' }

        /**
         * Not supported
         * @see {@link https://wise.atlassian.net/browse/WB-130}
         * {key : "professionalAssociation",label: "Professional Association"},
         * {key : "limitedPartnership",label: "Limited Partnership"},
         * {key : "limitedLiabilityPartnership",label: "Limited Liability Partnership"},
         * {key : "professionalCorporation",label: "Professional Corporation"},
         */
      ]
    };
  },
  methods: {
    ...mapActions('kyb', [
      'createBusiness',
      'updateBusiness',
      'listAllBusiness'
    ]),
    ...mapMutations('kyb', ['setSelectedBusiness']),
    back() {
      if (this.$businessChecking === true && this.$personalChecking === true) {
        if (this.getAllAccount.length !== 0) {
          this.goToPreviousScreen(); /** @see {@link https://wise.atlassian.net/browse/WB-469} */
        } else {
          this.$router.push(
            '/kyc/accountType'
          ); /** @see {@link https://wise.atlassian.net/browse/WB-454} */
        }
      } else {
        this.logoutAndRedirect();
      }
    },
    submitForm() {
      this.$refs.businessForm.validate((valid) => {
        if (!valid) return;

        /** @type {import('@m/kyb/store/state').kybState['selectedBusinessType']}*/
        const selectedBusiness = this.getSelectedBusiness;
        /** @type {import('@m/user/store/state').userState['personDetails']}*/
        const personDetails = this.getPersonDetails;

        let methodName = ['createBusiness'];
        let payload = {
          ...this.business,
          phone: personDetails.phone,
          email: personDetails.email
        };
        if (selectedBusiness) {
          methodName = ['updateBusiness'];
          payload = {
            businessId: selectedBusiness.id,
            business: {
              ...this.business,
              phone: personDetails.phone,
              email: personDetails.email
            }
          };
        }

        const loader = this.showLoader();
        this[methodName](payload)
          .then(() => {
            this.$router.push('/kyb/businessAddress');
          })
          .catch((e) => {
            console.log(e);
            this.apiErrorMessage(e);
          })
          .finally(() => {
            loader.close();
          });
      });
    }
  }
};
</script>